.editor{
  width: 74%;
  border: 1px solid $color-2;
  box-shadow: $card-shadow;
  margin-bottom: 2rem;
  min-height: 20rem;
  &__Text {
    padding: 0 2rem;
  }
}
