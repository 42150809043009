.auth {
  width: 100vw;
  min-height: 100vh;
  @include flex(column, flex-start, center);
  &__container {
    width: 40rem;
    height: auto;
    background-color: $color-7;
    box-shadow: $card-shadow;
    padding-bottom: 3rem;
    margin-top: 20rem;
    @include flex(column, flex-start, center);
    & h2 {
      margin: 3rem 0;
    }
    &--form {
      width: 100%;
      @include flex(column, flex-start, center);
      &--labels {
        width: 70%;
        @include flex(column, flex-start, center);
        margin-bottom: 3rem;
        & span {
          align-self: flex-start;
          margin-bottom: 1rem;
          font-weight: lighter;
        }
        & input {
          width: 60%;
          outline: none;
          border: 1px solid $color-8;
          padding: .4rem .8rem;
          //font-size: 1.3rem;
          transition: .3s;
          &:focus {
            border: 2px solid $color-0;
          }
        }
      }
    }
    &--bottomNav {
      width: 100%;
      margin-top: 2rem;
      @include flex(row, center, center);
      & a {
        margin: 0 1rem;
      }
    }
    &--message {
      margin: 0 10rem 2rem 10rem;
      font-size: 1.2rem;
      color: $font-color-2;
      text-align: center;
    }
  }
}
