.home {
  max-height: 100rem;
  min-height: 100rem;
  overflow: scroll;
  &__card {
    width: 110rem;
    height: 25rem;
    border-bottom: 1px solid $color-2;
    @include flex(column, space-evenly, flex-start);
    box-shadow: 0px 4px 5px -6px rgba(0,0,0,0.75);
    padding: 0 1rem;
    & h5 {
      color: $color-2;
    }
    & p {
      line-height: 1.8rem;
    }
    & button {
      background-color: $color-1;
      color: $color-2;
      padding: .5rem 1rem;
      border: 1px solid $color-2;
      border-radius: 1rem;
      transition: .3s;
      cursor: pointer;
      &:hover {
        background-color: $color-2;
        color: $font-color-0;
      }
    }
  }
}
