.btn {
  outline: none;
  text-decoration: none;
  font-size: 1.5rem;
  background-color: $color-2;
  color: $color-7;
  padding: .5rem 1.2rem;
  cursor: pointer;
  backface-visibility: hidden;
  transition: .3s;
  border: 1px solid $color-2;
  @include phone {
    font-size: 1rem;
    padding: .3rem .8rem;
  }
  &:active{
    outline: none;
  }
  &:focus{
    outline: none;
  }
  &:hover {
    background-color: $color-7;
    color: $color-2;
    border: 1px solid $color-2;
    border-radius: .5rem;
    //transform: scale(1.1);
    box-shadow:  0px 4px 25px -2px $color-1;
  }
}

.btn1 {
  outline: none;
  text-decoration: none;
  font-size: 1.5rem;
  background-color: $color-7;
  color: $color-2;
  padding: .5rem 1.2rem;
  cursor: pointer;
  backface-visibility: hidden;
  transition: .3s;
  border: 1px solid $color-7;
  //border: 1px solid $color-2;
  &:active{
    outline: none;
  }
  &:focus{
    outline: none;
  }
  &:hover {
    background-color: $color-2;
    color: $color-7;
    border: 1px solid $color-2;
    border-radius: .5rem;
    //transform: scale(1.1);
    box-shadow:  0px 4px 25px -2px $color-1;
  }
}
