#contactPage {
  min-height: 100vh;
}

.contact{
  & h1 {
    margin: 3rem 0 1.5rem 0;
    text-align: center;
  }
  &--h1 {
    font-family: $header-fontStyle;
    color: $color-2;
    margin: 5rem 0;
  }
  &__container {
    padding: 3rem 0;
    margin: 3rem 0;
    width: 115rem;
    @include flex(column, space-around, center);
    flex-wrap: wrap;
    border-top: 1px solid $color-2;
    border-bottom: 1px solid $color-2;
    @include tabletbig{
      width: 90rem;
    }
    @include tabletsmall {
      width: 60rem;
    }
    &--card {
      overflow: hidden;
      margin: 3rem 2rem;
      box-shadow: 0px 2px 5px -1px rgba(0,0,0,0.75);
      border-radius: .5rem;
      @include flex(column, flex-start, center);
      width: 40%;
      height: 18rem;
      border: 1px solid $color-2;
      text-align: center;
      background-color: $color-1;
      &--h1 {
        margin: 2rem 0;
        font-family: $header-fontStyle;
        background-color: $color-1;
        padding: .2rem 1.4rem;
        @include tabletsmall{
          font-size: 2rem;
        }
      }
      &--h2 {
        font-family: $header-fontStyle;
        margin: 1rem 0;
        @include tabletsmall{
          font-size: 1.8rem;
        }
      }
      &--span {
        margin: .5rem 1rem;
      }
    }
    &--directors {
      @include flex(row, center, center);
      width: 100%;
      @include phone{
        @include flex(column, center, center)
      }
    }
  }
}
