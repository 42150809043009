.create {
  width: 100vw;
  min-height: 100vh;
  @include flex(column, flex-start, center);
  &__form {
    margin: 8rem 0;
    width: 95rem;
    background-color: $color-7;
    @include flex(column, center, flex-start);
    box-shadow: 0px 2px 8px -3px rgba(107, 107, 107, 0.75);
    border-radius: .5rem;
    @include tabletbig {
      width: 90rem;
    }
    @include tabletsmall{
      width: 60rem;
    }
    @include phone {
      width: 30rem;
    }
    &--hdiv {
      border-bottom: 1px solid $color-8;
      width: 100%;
    }
    &--header {
      //font-family: $header-fontStyle;
      padding: 1.4rem 2.2rem;
    }
    &--Hlabels {
      width: 100%;
      height: 5rem;
      @include flex(column, space-between, flex-start);
      margin-bottom: 4rem;
      & h5 {
        font-weight: normal;
        //font-family: $header-fontStyle;
      }
      & input {
        width: 45rem;
        padding: .4rem .8rem;
        font-size: 1.2rem;
        transition: .1s;
        backface-visibility: hidden;
        background-color: $color-1;
        border: 1px solid $color-8;
        @include tabletsmall{
          width: 100%;
        }
        &:focus {
          outline: none;
          background: white;
          border:1px solid $color-0;
          box-shadow:0 0 3px $color-0;
          -moz-box-shadow:0 0 3px $color-0;
          -webkit-box-shadow:0 0 3px $color-0;
        }
      }
    }
    &--Tlabels {
      width: 100%;
      height: 18rem;
      margin-bottom: 4rem;
      @include flex(column, space-between, flex-start);
      & h5 {
        font-weight: normal;
      }
      & textarea {
        width: 45rem;
        height: 15rem;
        padding: .4rem 1rem;
        font-size: 1.3rem;
        transition: .3s;
        backface-visibility: hidden;
        background-color: $color-1;
        border: 1px solid $color-8;
        font-family: 'Open Sans', sans-serif;
        @include tabletsmall{
          width: 100%;
        }
        &:focus {
          outline: none;
          background: white;
          border:1px solid $color-0;
          box-shadow:0 0 3px $color-0;
          -moz-box-shadow:0 0 3px $color-0;
          -webkit-box-shadow:0 0 3px $color-0;
        }
      }
    }
    &--Card {
      @include flex(row, flex-start, flex-start);
      margin-top: 2rem;
      padding: 0 2.2rem;
      width: 100%;
      border-bottom: 1px solid $color-8;
      &--L{
        width: 33%;
        & h3 {
          font-weight: normal;
          //font-family: $header-fontStyle;
        }
      }
      &--R {
        width: 67%;
        padding-top: .6rem;
      }
      &--btn {
        width: 100%;
        text-align: end;
        padding: 2rem 5rem 2rem 0;
      }
    }
    &--body{
      width: 100rem;
      padding: 5rem 8rem;
      @include flex(column, flex-start, center);
      &--Hlabels {
        width: 100%;
        @include flex(column, space-between, center);
        height: 8rem;
      }
    }
  }
}

#flexCenter {
  @include flex(column, space-between, center)
}
