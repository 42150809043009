.Navbar {
  position: sticky;
  top:0;
  width: 100vw;
  height: 6rem;
  background-color: $color-0;
  box-shadow: 0px 1px 6px -2px rgba(0,0,0,0.75);
  padding: 3rem;
  @include flex(row, space-between, center);
  z-index: 3;
  @include phone{
    padding: 1.5rem;
    height: 8rem;
  }
  &__left {
    @include flex(row, space-between, center);
    @include tabletsmall {
      width: 20rem;
    }
    @include phone {
      @include flex(column, space-between, flex-start);
      width: 50%;
    }
    & div {
      width: 22rem;
      @include phone{
        width: 100%;
      }
    }
    & h1 {
      line-height: 2rem;
      font-size: 1.5rem;
      font-weight: normal;
      @include tabletsmall{
        font-size: 2rem;
      }
      @include phone{
        font-size: 1.2rem;
        margin-left: .4rem;
      }
    }
    & ul {
      margin-left: 3rem;
      @include flex(row, space-between, center);
      @include tabletsmall{
        & li {
          margin: 0 .5rem;
          @include phone{
            margin: .2rem .5rem;
          }
        }
      }
      @include phone {
        flex-wrap: wrap;
        width: 100%;
        margin: 0;
      }
    }
    &--links {
      font-size: 1.5rem;
      backface-visibility: hidden;
      transition: .3s;
      margin: 0 .5rem;
      @include phone {
        font-size: 1.2rem;
      }
      &:hover {
        color: $color-5;
      }
    }
  }
  &__right {
    @include flex(row, space-between, center);
    width: auto;
    & li {
      margin: 0 1rem;
    }
    @include tabletsmall{
      width: 28rem;
    }
    @include phone {
      font-size: 1.2rem;
      width: 50%;
      @include flex(row, flex-end, center);
      flex-wrap: wrap;
    }
      & li {
        @include phone {
          margin: .3rem .8rem;
        }
      }
  }
}
