.city {
  width: 100vw;
  min-height: 100vh;
  @include flex(column, flex-start, center);
  &__header {
    margin: 3rem 0;
    font-size: 4rem;
  }
  &__container {
    width: 80rem;
    height: auto;
    @include flex(column, flex-start, center);
    border: 1px solid $color-2;
    box-shadow: $card-shadow;
    background-color: $color-7;
    padding-bottom: 3rem;
    margin-bottom: 4rem;
    @include phone {
      width: 95%;
    }
    &--commissioners {
      width: 50%;
      @include flex(row, center, center);
      margin-bottom: 1rem;
      @include phone {
        width: 80%;
      }
      & h4 {
        font-weight: normal;
        margin-right: 1rem;
      }
    }
    & h1 {
      margin: 2rem 0 3rem 0;
    }
    &--deleteIcon {
      cursor: pointer;
      transition: .3s;
      &:hover {
        color: red;
      }
      &:active {
        transform: scale(.9);
      }
    }
  }
  &__form {
    width: 45%;
    @include flex(column, flex-start, center);
    @include phone {
      width: 80%;
    }
    &--label {
      width: 100%;
      @include flex(row, space-between, center);
      margin-top: 1rem;
      & input {
        border: 1px solid $color-2;
        padding: .5rem .8rem;
        outline: none;
        transition: .3s;
        font-size: 1.4rem;
        &:focus {
          outline: 1px solid green;
        }
      }
    }
  }
  &__form1 {
    margin-top: 1rem;
    width: 60%;
    @include flex(row, space-between, center);
    @include phone {
      width: 90%;
      @include flex(column, space-between, center)
    }
    &--label {
      @include flex(column, center, flex-start);
      margin: 0 1rem;
      & span {
        margin-bottom: .5rem;
      }
      & input {
        border: 1px solid $color-2;
        padding: .5rem .8rem;
        outline: none;
        transition: .3s;
        font-size: 1.4rem;
        &:focus {
          outline: 1px solid green;
        }
      }
    }
  }
}
