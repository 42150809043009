.footer {
  position: relative;
  bottom: 0;
  width: 100vw;
  height: 10rem;
  background-color: $color-0;
  text-align: center;
  box-shadow: 0px -1px 6px -2px rgba(0,0,0,0.75);
  @include flex(column, space-around, center);
}
