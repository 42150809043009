.delete {
  position: fixed;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  background-color: rgba(215, 215, 215, 0.41);
  @include flex(row, center, center);
  &__box {
    background-color: $color-7;
    opacity: 1;
    width: 45rem;
    height: 15rem;
    border: 1px solid $color-8;
    @include flex(column, center, center);
    border-radius: .5rem;
    &--btns {
      margin-right: 3rem;
      margin-top: 4rem;
      width: 40%;
      align-self: flex-end;
      @include flex(row, space-around, center)
    }
  }
}
