// This is the base settings file.

*, *::after, *::before{
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

// if you're wondering why there is no list style, this is why.
ul {
  list-style: none;
}

// this right here makes that stupid scroll bar on the side of the website gone
*::-webkit-scrollbar {
  display: none;
}

a {
  text-decoration: none;
  color: $font-color-1
}


// When choosing a fontsize dont us px, use rem. this right here sets the default font size to 10px so when you
// set a font size of 1.5rem, its 15px and so on. DO NOT CHANGE THIS VALUE.
html {
  // rem setting
  font-size: 62.5%; // = 10px relative to 16px default
}

body {
  // reset to the total content box
  box-sizing: border-box;

  // font settings

  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


  // THIS IS THE BASE FONT SIZE, 16px
  font-size: 1.6rem;
  @include phone{
    font-size: 1.4rem;
  }
  //letter-spacing: .25rem;

  // wordwrap settings
  //hyphens: auto;

  // set initial background color
  background-color: $color-1;

  // hide the scroll bar out of preference
  &::-webkit-scrollbar {
    display: none;
  }
}
