//CARD
.blogCard {
  position: relative;
  transform: translateX(-50%);
  left: 50%;
  width: 110rem;
  height: 26rem;
  border-bottom: 1px solid $color-2;
  @include flex(column, space-evenly, flex-start);
  box-shadow: 0px 2px 8px -3px rgba(107, 107, 107, 0.75);
  padding: 0 3rem;
  background-color: $color-7;
  margin: 3rem 0;
  border-radius: .5rem;
  @include tabletbig {
    width: 90rem;
  }
  @include tabletsmall{
    width: 60rem;
  }
  @include phone {
    width: 35rem;
    height: 35rem;
  }
  &--head {
    @include flex(row, space-between, center);
    width: 100%;
  }
  & h1 {
    font-family: $header-fontStyle;
  }
  & h5 {
    color: $color-2;
    font-family: $header-fontStyle;
    font-size: 1.8rem;
  }
  & p {
    line-height: 145%;
    font-weight: 300;
  }
  &__Link {
    background-color: $color-7;
    color: $color-2;
    padding: .5rem 1rem;
    border: 1px solid $color-2;
    border-radius: 1rem;
    transition: .3s;
    cursor: pointer;
    &:hover {
      background-color: $color-2;
      color: $font-color-0;
    }
  }
}

//CONTENT

.blogContent {
  width: 100vw;
  min-height: 100vh;
  @include flex(column, flex-start, center);
  &__container {
    box-shadow: 0px 2px 8px -3px rgba(107, 107, 107, 0.75);
    margin: 3rem 0;
    width: 80rem;
    @include flex(column, flex-start, center);
    background-color: $color-7;
    @include tabletsmall{
      width: 60rem;
    }
    @include phone {
      width: 35rem;
    }
    &--header {
      padding: 0 4rem;
      height: 18rem;
      @include flex(column, center, center);
      width: 100%;
      border-bottom: 1px solid $color-8;
      //box-shadow: 0px 8px 6px -10px rgba(0,0,0,0.75);
      margin-bottom: 5rem;
      @include phone {
        text-align: center;

      }
      & h1{
        font-size: 4.2rem;
        margin-bottom: 1.5rem;
        font-family: $header-fontStyle;
        @include phone {
          font-size: 3.5rem;
        }
      }
      & h5 {
        font-size: 1.8rem;
        color: $color-2;
        text-transform: uppercase;
        font-family: $header-fontStyle;
      }
    }
    &--body{
      margin-bottom: 8rem;
      width: 80%;
      @include flex(column, flex-start, center);
      & p {
        //margin-bottom: 3rem;
        line-height: 160%;
        font-weight: 300;
        white-space: pre-line;
      }
      & h2 {
        font-size: 3rem;
        margin-top: 1.5rem;
        margin-bottom: 5rem;
        font-weight: 700;
        line-height: 135%;
        font-family: $header-fontStyle;
      }
    }
  }
}

.Link {
  border: 1px solid $color-2;
  padding: 1rem 2rem;
  margin-bottom: 8rem;
  transition: .2s;
  cursor: pointer;
  border-radius: .5rem;
  color: $color-7;
  background-color: $color-2;

  &:hover {
    background-color: $color-7;
    color: $color-2;
    border: 1px solid $color-2
  }
}


//CONTACTFORM
.contact {
  width: 100%;
  @include flex(column, flex-start, center);
  &__h1 {
    font-family: $header-fontStyle;
    margin: 5rem 0;
    @include phone{
      margin: 5rem 2rem;
      text-align: center;
    }
  }
  &__form {
    padding-bottom: 4rem;
    width: 100%;
    @include flex(column, flex-start, center);
    &--inputLabel {
      margin-bottom: 5rem;
      height: 6rem;
      @include flex(column, space-between, center);
      & h2{
        font-family: $header-fontStyle;
        font-size: 2rem;
        font-weight: normal;
      }
      & input {
        width: 45rem;
        padding: .3rem .8rem;
        font-size: 1.2rem;
        transition: .3s;
        backface-visibility: hidden;
        background-color: $color-1;
        border: 1px solid $color-8;
        &:focus {
          outline: none;
          background: white;
          border:1px solid $color-0;
          box-shadow:0 0 3px $color-0;
          -moz-box-shadow:0 0 3px $color-0;
          -webkit-box-shadow:0 0 3px $color-0;
        }
        @include phone{
          width: 30rem;
        }
      }
    }
    &--textLabel {
      margin-bottom: 5rem;
      height: 19rem;
      @include flex(column, space-between, center);
      & h2{
        font-family: $header-fontStyle;
        font-weight: normal;
        font-size: 2rem;
      }
      & textarea {
        width: 45rem;
        height: 15rem;
        padding: .4rem 1rem;
        font-size: 1.3rem;
        font-family: 'Open Sans', sans-serif;
        transition: .3s;
        backface-visibility: hidden;
        background-color: $color-1;
        border: 1px solid $color-8;
        &:focus {
          outline: none;
          background: white;
          border:1px solid $color-0;
          box-shadow:0 0 3px $color-0;
          -moz-box-shadow:0 0 3px $color-0;
          -webkit-box-shadow:0 0 3px $color-0;
        }
        @include phone {
          width: 30rem;
        }
      }
    }
    &--btn {
      background-color: $color-2;
      padding: .8rem 1.4rem;
      border: 1px solid $color-2;
      color: $color-7;
      transition: .2s;
      cursor: pointer;
      margin-bottom: 5rem;
      border-radius: .5rem;
      &:hover {
        color: $color-2;
        background-color: $color-7;
        border: 1px solid $color-2;
      }
    }
  }
}

#contactForm {
  border-top: 1px solid $color-8;
}
