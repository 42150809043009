@mixin flex($direction ,$justify, $align) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}
@mixin center{
  position: relative;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
