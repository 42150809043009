// color variables

// website color scheme
$color-0: #689b8b;
$color-1: #ebebeb;
$color-2: #6B8199;
$color-3: #EBCC9D;
$color-4: #EBB89D;
$color-5: #2f5f4e;
$color-6: #C9DCD6;
$color-7: #f5f5f5;
$color-8: #d7d7d7;

$font-color-0: #ffffff;
$font-color-1: #111111;
$font-color-2: #696969;


$card-shadow: 0px 2px 5px -1px rgba(168, 168, 168, 0.49);


// media queries
$screen-phone: 600px;
$screen-tabletsm: 900px;
$screen-tabletlg: 1200px;

//font styles {
$header-fontStyle: Bookman, URW Bookman L, serif;


@mixin phone {
  @media (max-width: #{$screen-phone}) {
    @content;
  }
}

@mixin tabletsmall {
  @media (max-width: #{$screen-tabletsm}) {
    @content;
  }
}

@mixin tabletbig {
  @media (max-width: #{$screen-tabletlg}) {
    @content;
  }
}
