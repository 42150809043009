.covenant {
  min-height: 100vh;
  @include flex(column, center, center);
  &__body {
    z-index: 0;
  }
  &__nav {
    width: 20rem;
    height: 5rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    background-color: $color-1;
    box-shadow: $card-shadow;
    border-radius: .5rem;
    padding: .5rem 0;
    @include flex(column, space-between, center);
    &--nav {
      width: 50%;
      @include flex(row, space-between, center);
      cursor: pointer;
    }
  }
}

.react-pdf__Page__canvas {
  @include phone {
    width: 33rem;
    height: 45rem;
  }
}
