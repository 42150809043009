.successBar {
  position: sticky;
  top: 6rem;
  width: 100%;
  background-color: green;
  color: $font-color-0;
  padding: .5rem 0;
  text-align: center;
  z-index: 1;
  @include phone {
    top: 16rem;
  }
}

.errorBar {
  top: 6rem;
  position: sticky;
  width: 100%;
  background-color: red;
  color: $font-color-0;
  padding: .5rem 0;
  text-align: center;
  z-index: 1;
  @include phone {
    top: 16rem;
  }
}
